import styled from 'styled-components';

import { FaGithubSquare, FaLinkedin, FaTwitterSquare } from 'react-icons/fa';

export const Icons = styled.section`
     display: flex;
    justify-content: center;
    margin-bottom: 5rem;

    svg {
        color: #4CAF50;
        transition: color 0.25s; // Add transition to svg

        &:hover {
            color: green; // svg color will transition on hover
        }
    }
            
`;

export const GitHub = styled(FaGithubSquare)`
  font-size: 5rem;
  margin: .1rem;
`;

export const LinkedIn = styled(FaLinkedin)`
  font-size: 5rem;
  margin: .1rem;
`;

export const Twitter = styled(FaTwitterSquare)`
  font-size: 5rem;
  margin: .1rem;
`;