import React from 'react';
import Container from 'components/ui/Container';

import * as Styled from './styles';

const ConctactInfo = () => {

  return (
    <Container section> 
      <Styled.ContactInfoItem>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfvft5GiMrjd6w0SRjA-UcFyPfZV6a2oa6sX4k4cMZ0sTQVJg/viewform?embedded=true" title="contact-form" width="640" height="959" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </Styled.ContactInfoItem>
    </Container>
  );
};

export default ConctactInfo;
