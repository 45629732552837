import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';


import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Hero from 'components/Hero';
import SocialIcons from 'components/SocialIcons';
import ContactInfo from 'components/ContactInfo';

const ContactPage: React.FC = () => {

  const { markdownRemark } = useStaticQuery(graphql`
      query {
        markdownRemark(frontmatter: { category: { eq: "contact section" } }) {
          html
          frontmatter {
            title
            subtitle
            seotitle
            seodescription
          }
        }
      }
  `);

  const subtitle = markdownRemark.frontmatter.subtitle;
  const title = markdownRemark.frontmatter.title;
  const html = markdownRemark.html;
  const seotitle = markdownRemark.frontmatter.seotitle;
  const seodescription = markdownRemark.frontmatter.seodescription;

  return (
    <Layout>
      <SEO title={seotitle} description={seodescription}/>
      <Hero title={title} subtitle={subtitle}/>
      <ContactInfo />
      <SocialIcons />
    </Layout>
  );
};

export default ContactPage;