import React from 'react';

import * as Styled from './styles';
import Container from 'components/ui/Container';

const SocialIcons = () => {
 
  return (
    <Container section>
      <Styled.Icons>
        <a href="https://www.linkedin.com/in/ruthdillonmansfield/" target="_blank" rel="noreferrer">
          <Styled.LinkedIn />
        </a>
        <a href="https://github.com/ruthdillonmansfield/" target="_blank" rel="noreferrer">
          <Styled.GitHub />
        </a>
      </Styled.Icons>
    </Container>
  );
};

export default SocialIcons;
